<template>
  <div>    
    <v-app-bar      
      color="black"      
      style="max-height:180px"
      class="header-one-page-nav"
    >
      <div class="logo"  style="max-height:95px ; max-width:180px">
        <slot name="logo"></slot>
        <slot name="logo-dark"></slot>
      </div>         
    </v-app-bar>   
  </div>
</template>

<script>  
  export default {
    data: () => ({     
    }),    
  };
</script>